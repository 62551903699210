import React from "react";
import Hero from "../../components/hero/hero";
import {
  Top_collection,
  Auctions_categories,
  NewsLetter,
  Feature_collections,
  Partners,
} from "../../components/component";
import Meta from "../../components/Meta";
import ListComponent from "../../components/listComponent";

const HomePage = () => {
  return (
    <>
      <Meta title="Home" />
      <Hero />
      <Top_collection />
      {/* <Auctions_categories /> */}
      {/* <Feature_collections /> */}
      <Partners />
      <NewsLetter />
      {/* <ListComponent></ListComponent> */}
    </>
  );
};

export default HomePage;
